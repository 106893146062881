import {
  InternalStepProgress,
  InventoryConfirmation,
  InventoryForm,
  PageWithIntro,
  WizardLayout,
} from 'components';
import React, { useState } from 'react';
import { getFormState, handleFormSubmit } from '../../../utilities/formstate';
import { FORM_NAME } from '../../../utilities/inventory';
import { WistiaVideo } from 'components/widgets/WistiaVideo';
import { inventoryStep } from '../../../utilities/stepDefinitions';
import { stepIsComplete } from '../../../utilities/steps';
import { useTracking } from '../../../utilities/hooks';

const InventoryPage = () => {
  const formState = getFormState(FORM_NAME);
  const introInformation = getFormState('introInformation');
  const stepCompleted = stepIsComplete(inventoryStep);

  const initialValues = formState.values
    ? formState.values[
        Object.keys(formState.values)[Object.keys(formState.values).length - 1]
      ]
    : null;
  const [formStep, setFormStep] = useState(1);

  useTracking('Inventory');

  return (
    <PageWithIntro step={inventoryStep}>
      <WizardLayout text>
        <h1>Add Inventory Details</h1>
        <WistiaVideo id={inventoryStep.intro.videoID} />
        <InternalStepProgress
          stepsConfig={[
            {
              step: 1,
              label: '1. Add Inventory Details',
              component: (
                <InventoryForm
                  formStep={formStep}
                  setFormStep={setFormStep}
                  initialValues={initialValues}
                  working={formState.working || false}
                  onSubmit={handleFormSubmit(formState)}
                />
              ),
            },
            {
              step: 2,
              label: '2. Confirm Inventory Details',
              component: (
                <InventoryConfirmation
                  stepCompleted={stepCompleted}
                  introInformation={introInformation.values}
                  setFormStep={setFormStep}
                />
              ),
            },
          ]}
        />
      </WizardLayout>
    </PageWithIntro>
  );
};

export default InventoryPage;
